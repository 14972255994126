.section-banner {
    font-family: "Trade GothicÂ® Next W01",Arial,sans-serif;
    line-height: 1.2;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    display: block;
    position: relative;
}
.carousel-slide {
    font-family: "Trade GothicÂ® Next W01",Arial,sans-serif;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    position: relative;
}

.carousel-inner {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-item {
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    position: relative;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    display: block;
}

.container-1 {
    font-family: "Trade GothicÂ® Next W01",Arial,sans-serif;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    padding: 0 2em;
}

.carousel-caption-container {
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    position: absolute;
    z-index: 10;
    color: #fff;
    display: block !important;
    right: auto;
    left: auto;
    text-align: left;
    padding: 0 !important;
    min-height: 30%;
    bottom: 25%;
}

.row-1 {
    font-family: "Trade GothicÂ® Next W01",Arial,sans-serif;
    color: #fff;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: flex-start !important;
}

.col-1 {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-1 h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    font-size: 2.7rem;
    color: #003764;
}

.col-1 > p {
    line-height: 1.2;
    text-align: left;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 1.2rem;
    margin: 2rem 0 0 0;
    color: #003764;
}

.col-11 {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 1px;
    flex: 0 0 60.66667%;
    max-width: 60%;
}

.col-11 h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    font-size: 1.4rem;
    color: #003764;
}

.col-11 > p {
    line-height: 1.2;
    text-align: left;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 0.8rem;
    margin: 0rem 0 0 0;
    font-weight: 600;
    color: #003764;
}

.col-img-full {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
}

.section-content-exp {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    padding: 2rem 0;
}

.img-exp {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    border-style: none;
    display: block !important;
    max-width: 100%;
    height: auto;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 3rem;
}

.container-2 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    padding: 0 2em;
}

.row-2 {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-2 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    /* flex: 0 0 41.66667%;
    max-width: 41.66667%; */
}

.col-2 > h1 {
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.col-2 > p {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.section-content-biodevulc {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    padding: 7rem 0;
    margin-bottom: 3rem;
    position: relative;
}

.section-content-biodevulc2 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    /* padding: 7rem 0;
    margin-bottom: 3rem; */
    position: relative;
}

.container-3 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

.row-3 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
}

.col-3-img-content {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px; */
    /* flex: 0 0 50%;
    max-width: 50%; */
}

.img-fluid-exp {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block !important;
    max-width: 100%;
    height: auto;
    margin-right: auto !important;
    margin-left: auto !important;
}

.col-3 {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 69.33333%;
    max-width: 69.33333%;
}

.col-31 {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    /* flex: 0 0 69.33333%;
    max-width: 69.33333%; */
}

.col-31 > h1 {
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 1.8rem;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.col-31 > p {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.col-3 > h1 {
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.col-3 > p {
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.col-3-1 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
}

.biodevulc-powder-img {
    /* box-sizing: border-box; */
    /* display: block !important; */
    position: absolute;
    z-index: -1;
    top: 21rem;
    right: 0;
    width: 39%;
}

.biodevulc-powder-img2 {
    /* box-sizing: border-box; */
    /* display: block !important; */
    position: absolute;
    z-index: -1;
    top: 36.5rem;
    right: 0;
    width: 45%;
}

.section-teaser-exp {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    /* padding: 2rem 0; */
    position: relative;
    z-index: 2;
}

.container-4 {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    /* padding: 0 2em; */
    padding-bottom: 5rem;
}

.row-4 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-4 {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    /* flex: 0 0 50%;
    max-width: 50%; */
    margin-top: 2rem;
}

.col-4 > a {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #003764;
    text-decoration: none;
    background-color: transparent;
    transition: all .2s;
}

.col-4 > h1 {
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.col-4 > p {
    font-family: "Calibri";
    font-size: 1rem;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-weight: 300;
    margin-top: 2rem;
    /* margin-bottom: 5rem; */
}

.col-4 > footer {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: absolute;
    /* bottom: 0; */
}