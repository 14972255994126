body {
  margin: 0;
  /* font-family: 'Lato', sans-serif; */

  font-family: 'Poppins', sans-serif;
  /* text-shadow: 0px 0px; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

a {
  text-decoration: none;
  line-height: 1.5;
}
