.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:
      0px 3.4px 2.7px rgba(0, 0, 0, 0.022),
      0px 8.7px 6.9px rgba(0, 0, 0, 0.031),
      0px 17.7px 14.2px rgba(0, 0, 0, 0.039),
      0px 36.5px 29.2px rgba(0, 0, 0, 0.048),
      0px 100px 80px rgba(0, 0, 0, 0.07)
    ;

}

.heading {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    line-height: 1;
    font-size: 2.2rem;
    padding: 60px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.enq-button {
    --color: #003764;
    font-family: inherit;
    display: inline-block;
    width: 18em;
    height: 3.6em;
    line-height: 2.5em;
    margin: 20px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    font-weight: 500;
    color: var(--color);
   }
   
   .enq-button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 200px;
    width: 400px;
    border-radius: 50%;
   }
   
   .enq-button:hover {
    color: #fff;
   }
   
   .enq-button:before {
    top: 100%;
    left: 100%;
    transition: all .3s;
   }
   
   .enq-button:hover:before {
    top: -30px;
    left: -30px;
   }
   
   .enq-button:active:before {
    background: #003764;
    transition: background 0s;
   }