.sectionbenefits{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    padding: 3rem 0;
    background: #f3f3f3;
    padding-bottom: 3rem;
}

.beniimage {
    perspective: 1000px;
    backface-visibility: hidden;
    line-height: 1.2;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    border-style: none;
    max-width: 100%;
    height: auto;
    display: block !important;

}

.containerbenefits{
    color: rgb(0, 0, 0);
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    padding: 0 1em;
}
.rowbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center !important;
}

.colbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    /* flex: 0 0 41.66667%;
    max-width: 41.66667%; */
}
.colbeni >h1{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.colbeni >h2{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    color: #54a51c;
}

.colbeni >p{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.colbeni2{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}


.floatbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    /* float: right !important; */
}

.floatbeni >h3{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    letter-spacing: 1px;
    font-size: 2.2rem;
    color: #003764;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
}

.rowbeni2{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 2rem;
}

.colbeni3{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.wrapperbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    top: -2rem;
    left: 0;
    right: auto;
    bottom: auto;  
}

.wrapperbeni >p{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;
}


.btnbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    line-height: 1.2;
    border-radius: 0;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    color: #54a51c;
    background-color: transparent;
    background-image: none;
    border-color: #54a51c;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    min-width: 13rem;
    cursor: pointer;
    text-align: left;
}
.wrapperbeni2{
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    top: -2rem;
    right: 0;
    left: auto;
    bottom: auto;
}

.btnbeni2{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    line-height: 1.2;
    border-radius: 0;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    color: #54a51c;
    background-color: transparent;
    background-image: none;
    border-color: #54a51c;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    min-width: 13rem;
    cursor: pointer;
    text-align: left;
}

.imgbeni{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    border-style: none;
    display: block !important;
    max-width: 100%;
    height: auto;
}

.wrapperbeni3{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    top: auto;
    left: 0;
    right: auto;
    bottom: -2rem;
}

.wrapperbeni3 >p{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    font-size: 1rem;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;}
.btnbeni44{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    line-height: 1.2;
    border-radius: 0;
    transition: color 0.15s ease-in-out;
    color: #54a51c;
    background-color: transparent;
    background-image: none;
    border-color: #54a51c;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    min-width: 13rem;
    cursor: pointer;
    text-align: left;
}

.wrapperbeni5{
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: rgb(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    top: auto;
    right: 0;
    left: auto;
    bottom: -2rem;
}

.btnbeni55{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Calibri";
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    line-height: 1.2;
    border-radius: 0;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    color: #54a51c;
    background-color: transparent;
    background-image: none;
    border-color: #54a51c;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    min-width: 13rem;
    cursor: pointer;
    text-align: left;
}