.section1{
font-family: "Trade Gothic® Next W01",Arial,sans-serif;
font-size: 1rem;
font-weight: 300;
line-height: 1.2;

text-align: left;

box-sizing: border-box;
display: block;
/* padding: 7rem 0; */
padding-top: 0;}


.container2{
   
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;

    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row3{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top:15px;
}

.article4{
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: transparent;
font-family: "Trade Gothic® Next W01",Arial,sans-serif;
font-size: 1rem;
font-weight: 300;
line-height: 1.2;
color: #000;
text-align: left;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
display: block;
position: relative;
width: 100%;
min-height: 1px;
padding-right: 15px;
padding-left: 15px;
/* flex: 0 0 50%;
max-width: 50%; */
padding-top: 6rem;}


.article4 >h1 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #003764;
}

.article4 >p{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.article5{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-height: 1px;
    /* flex: 0 0 50%;
    max-width: 50%; */
    position: relative;
    z-index: 2;
    padding: 0;
}

.picture{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: #000;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    border-style: none;
    display: block !important;
    max-width: 100%;
    height: auto;
}

.but{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: "Trade Gothic® Next W01",Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    line-height: 1.2;
    border-radius: 0;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    color: #54a51c;
    background-color: transparent;
    background-image: none;
    border-color: #54a51c;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    min-width: 13rem;
    cursor: pointer;
}